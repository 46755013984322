import { AdvertItem, Dispatch, stateProps } from '@lovejunk/core'
import { fetchRecentAdverts, recentAdvertsLens, recentAdvertsRootLens as rootLens } from 'entities/recent-adverts'
import React, { FC, useEffect } from 'react'
import { connect } from 'react-redux'
import { styled } from 'styled'
import { PerPageConfig } from 'types'
import { device } from 'utils/css'
import { isMobile } from 'utils/environment'

import Column from './containers/Column'
import Listings, { getPerPage } from './Listings'
import PanelTitle from './Panel/Title'
import Highlight from './text/Highlight'

interface DispatchProps {
  fetchRecentAdverts: Dispatch<number>
}

interface StateProps {
  items: AdvertItem[]
}

type Props = DispatchProps & StateProps

const perPageConfig: PerPageConfig = {
  desktop: 8,
  mobile: 6,
  mobileSmall: 6,
}

const RecentListings: FC<Props> = ({ fetchRecentAdverts, items }) => {
  useEffect(() => {
    fetchRecentAdverts(getPerPage(perPageConfig))
  }, [fetchRecentAdverts])

  return (
    <Root>
      <Title>
        Check <Prices color="selected">prices</Prices> of recent listings
      </Title>
      <Listings columns={isMobile ? 3 : 4} disableNavigation items={items} itemsPerPage={getPerPage(perPageConfig)} />
    </Root>
  )
}

const Root = Column

const Prices = styled(Highlight)`
  text-decoration: underline;
`

const Title = styled(PanelTitle)`
  margin-bottom: 2em;

  @media ${device.mobile} {
    margin-bottom: 1em;
    padding: 0 1em;
  }
`

const mapDispatchToProps = {
  fetchRecentAdverts,
}

const mapStateToProps = stateProps(rootLens)(state => ({
  items: recentAdvertsLens(state).get(),
}))

export default connect(mapStateToProps, mapDispatchToProps)(RecentListings)
