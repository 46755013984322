import { Identity, MapProps as MapPropsBase } from '@lovejunk/core'
import GoogleMapReact from 'google-map-react'
import React, { FC, useCallback, useEffect, useRef, useState } from 'react'
import { styled } from 'styled'

interface MapProps extends MapPropsBase {
  libraries?: string[]
}

const apiKey = process.env.REACT_APP_GOOGLE_API_KEY

const radiusToZoom: Identity<number> = radius => 23.5 - 3.5 * Math.log10(radius)

const circleDefaultOptions = {
  fillOpacity: 0.3,
  fillColor: 'transparent',
  strokeOpacity: 0.5,
  strokeColor: '#000000',
  strokeWeight: 1.0,
}

const Map: FC<MapProps> = ({ exact, height, latLng, libraries, radius = 350, showCircle }) => {
  const [isApiLoaded, setApiLoaded] = useState(false)
  const mapRef = useRef<any>()
  const mapsRef = useRef<any>()

  const onGoogleApiLoaded = useCallback(
    // @ts-ignore
    ({ map, maps }) => {
      mapRef.current = map
      mapsRef.current = maps

      setApiLoaded(true)
    },
    [],
  )

  useEffect(() => {
    if (!isApiLoaded) return

    const { current: map } = mapRef
    const { current: maps } = mapsRef

    const marker = maps && latLng && new maps.Marker({ map: exact ? map : null, position: latLng })
    const circle =
      maps &&
      latLng &&
      new maps.Circle({ ...circleDefaultOptions, map: showCircle || !exact ? map : null, center: latLng, radius })

    return () => {
      if (marker) marker.setMap(null)
      if (circle) circle.setMap(null)
    }
  }, [exact, isApiLoaded, latLng, radius, showCircle])

  const zoom = radiusToZoom(exact && !showCircle ? 100 : radius)

  if (!apiKey) return null

  return (
    <Root height={height}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: apiKey, libraries }}
        center={latLng}
        zoom={zoom}
        yesIWantToUseGoogleMapApiInternals
        onGoogleApiLoaded={onGoogleApiLoaded}
      />
    </Root>
  )
}

interface RootProps {
  height?: number
}

const Root = styled.div<RootProps>`
  height: ${({ height = 8 }) => height}em;
  width: 100%;
`

export default Map
