import {
  action,
  ActionBundle,
  apiAction,
  failureAction,
  FailureActionFactory,
  fetchAdvertsApi,
  FetchAdvertsResponse,
  initialFilter,
  marshalFilter,
  payloadAction,
  withEndpoint,
} from '@lovejunk/core'
import { StatelessPayloadThunk } from 'types/thunk'

import {
  FETCH_RECENT_ADVERTS_FAILURE,
  FETCH_RECENT_ADVERTS_START,
  FETCH_RECENT_ADVERTS_SUCCESS,
  FetchRecentAdverts,
  FetchRecentAdvertsFailure,
  FetchRecentAdvertsStart,
  FetchRecentAdvertsSuccess,
} from './types'

export const fetchRecentAdvertsFailure: FailureActionFactory<FetchRecentAdvertsFailure> =
  failureAction(FETCH_RECENT_ADVERTS_FAILURE)

export const fetchRecentAdvertsActionBundle: ActionBundle<
  FetchRecentAdvertsStart,
  FetchRecentAdvertsSuccess,
  FetchRecentAdvertsFailure,
  FetchAdvertsResponse
> = [action(FETCH_RECENT_ADVERTS_START), payloadAction(FETCH_RECENT_ADVERTS_SUCCESS), fetchRecentAdvertsFailure]

export const fetchRecentAdverts: StatelessPayloadThunk<number, FetchRecentAdverts> = pageSize =>
  withEndpoint(
    'adverts',
    advertsUrl => dispatch =>
      dispatch(
        apiAction(fetchRecentAdvertsActionBundle)(
          fetchAdvertsApi(advertsUrl, marshalFilter({ ...initialFilter, pageSize, matched: true })),
        ),
      ),
    fetchRecentAdvertsFailure,
  )
