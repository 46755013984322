import { Color } from '@lovejunk/core'
import { styled } from 'styled'

interface Props {
  color?: Color
}

export default styled.span<Props>`
  color: ${({ color = 'primary', theme: { colors } }) => colors[color]};
`
