import { createStatusIndicator, StatusCellProps, StatusTextRenderer } from '@lovejunk/core'
import React from 'react'
import { styled } from 'styled'

import CenteredRow from '../containers/CenteredRow'
import BaseText from '../text/BaseText'

const Cell = styled(CenteredRow)<StatusCellProps>`
  border-radius: 0.75em;
  border: 1px solid ${({ theme: { colors }, color = 'white' }) => colors[color]};
  padding: 0.125em 0.375em;
`

const LIVE_INDICATOR_SIZE = 0.5

const Live = styled.div`
  height: ${LIVE_INDICATOR_SIZE}em;
  width: ${LIVE_INDICATOR_SIZE}em;
  border-radius: ${LIVE_INDICATOR_SIZE / 2}em;
  background-color: ${({ theme: { colors } }) => colors.primary};
  margin-right: ${LIVE_INDICATOR_SIZE / 2}em;
`

const StatusText = styled(BaseText)`
  font-size: 0.625em;
  color: ${({ theme: { colors } }) => colors.white};
`

const liveStatusTextRenderer: StatusTextRenderer = text => (
  <Cell color="primary">
    <Live />
    <StatusText>{text}</StatusText>
  </Cell>
)

const statusTextRenderer: StatusTextRenderer = text => (
  <Cell>
    <StatusText>{text}</StatusText>
  </Cell>
)

export default createStatusIndicator({
  active: liveStatusTextRenderer,
  cancelled: statusTextRenderer,
  collected: statusTextRenderer,
  expired: statusTextRenderer,
  helpunderway: statusTextRenderer,
})
