import {
  advertDuration,
  AdvertItem,
  Color,
  createImgixParams,
  Format,
  formatDurationInSeconds,
  formatPrice,
  isAdvertItemPriceVisible,
  isDefined,
  justifyContent,
  LOVEJUNK_BOOKINGS_URL,
  LOVEJUNK_STAGING_BOOKINGS_URL,
  RowContent,
  undefineFalse,
  undefineIfAllValuesUndefined,
} from '@lovejunk/core'
import NavigationPath from 'navigation/paths'
import React, { FC, useMemo } from 'react'
import { generatePath } from 'react-router'
import { Link, LinkProps } from 'react-router-dom'
import { css, styled } from 'styled'
import { toSeoReference } from 'utils'
import { isMobile, isStagingEnv } from 'utils/environment'

import { ReactComponent as HeartBorderIcon } from '../../assets/icons/heart-border.svg'
import { ReactComponent as HeartSolidIcon } from '../../assets/icons/heart-solid.svg'
import CenteredRow from '../containers/CenteredRow'
import Column from '../containers/Column'
import Text from '../text/Text'
import StatusIndicator from './StatusIndicator'

export type Size = 'medium' | 'large'

interface OwnProps {
  hasAnyRole: boolean
  isCollector: boolean
  isJunkLover: boolean

  hideDuration?: boolean
  hideLiveReusable?: boolean
  isReusableFilterOn?: boolean
  passive?: boolean
  size?: number
}

type Props = AdvertItem & OwnProps

const formatDuration: Format<AdvertItem> = ({ matchedTimeInSeconds, offeredAt }) =>
  isDefined(matchedTimeInSeconds) ? formatDurationInSeconds(matchedTimeInSeconds) : advertDuration(offeredAt)

const ListingsItem: FC<Props> = ({ hasAnyRole, passive = true, isCollector, isJunkLover, size = 150, ...advert }) => {
  const {
    bestCounterOffer,
    imageUrl,
    isJunkRecycled,
    isJunkReused,
    isReusable,
    isReusableFilterOn,
    isUlez,
    postcodeDistrict,
    price,
    reference,
    hideDuration,
    hideLiveReusable,
    status = 'active',
    supplierType,
    title,
  } = advert

  const isActive = status === 'active'
  const showDuration = !hideDuration && !isCollector && (status === 'collected' || status === 'helpunderway')
  const showBestCounterOffer = isActive && isCollector
  const showOfferPrice = !isActive || isCollector
  const showPostcode = isReusableFilterOn && isReusable && isActive
  const isPriceVisible = useMemo<boolean>(
    () => isAdvertItemPriceVisible({ isCollector, isJunkLover, isReusable, isReusableFilterOn, status }),
    [isCollector, isJunkLover, isReusable, isReusableFilterOn, status],
  )
  const shouldRenderReusable = !hideLiveReusable && isActive && isReusable
  const shouldRenderReused = isJunkReused || isJunkRecycled || supplierType === 'junklover'

  const topRowLeft = useMemo(
    () => undefineIfAllValuesUndefined({ price: isPriceVisible && showOfferPrice ? price : undefined }),
    [isPriceVisible, price, showOfferPrice],
  )

  const topRowRight = useMemo(
    () =>
      undefineIfAllValuesUndefined({
        bestCounterOffer: isPriceVisible && showBestCounterOffer ? bestCounterOffer : undefined,
        shouldRenderReused: undefineFalse(shouldRenderReused),
      }),
    [bestCounterOffer, isPriceVisible, showBestCounterOffer, shouldRenderReused],
  )

  const seoReference = useMemo(() => toSeoReference(reference, title), [reference, title])
  const to = useMemo(() => generatePath(NavigationPath.Advert, { seoReference }), [seoReference])
  const Root = useMemo(
    () => (passive ? PassiveRoot : (props: Omit<LinkProps, 'to'>) => <ActiveRoot {...props} to={to} />),
    [passive, to],
  )

  const addParams = useMemo(() => createImgixParams(size), [size])

  return (
    <Root>
      <Image src={addParams(imageUrl)} alt={`${title} for removal and disposal`} />
      <Gradient />
      <Content>
        <Title>{title}</Title>
        <Body>
          <TopRow leftSide={isDefined(topRowLeft)} rightSide={isDefined(topRowRight)}>
            {isDefined(topRowLeft) ? <Price>{formatPrice(topRowLeft.price)}</Price> : null}
            {isDefined(topRowRight) ? (
              <CenteredRow>
                {isDefined(topRowRight.bestCounterOffer) ? (
                  <Price color="tertiary">{formatPrice(topRowRight.bestCounterOffer)}</Price>
                ) : null}
                {topRowRight.shouldRenderReused ? <Reused color="primary" /> : null}
              </CenteredRow>
            ) : null}
          </TopRow>
          {shouldRenderReusable ? <Reusable color="white" /> : null}
          {isDefined(postcodeDistrict) && showPostcode ? <Postcode isUlez={isUlez}>{postcodeDistrict}</Postcode> : null}
        </Body>
        <Footer leftSide={showDuration} rightSide>
          {showDuration ? <Duration className="duration">{formatDuration(advert)}</Duration> : null}
          {hasAnyRole || isActive ? <StatusIndicator isCollector={isCollector} status={status} /> : null}
        </Footer>
      </Content>
    </Root>
  )
}

const borderRadius = 0.25 * Number(!isMobile) + 'em'

const Image = styled.img`
  border-radius: ${borderRadius};
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
`

const Gradient = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: linear-gradient(to bottom, rgba(0, 0, 0) 0%, transparent 40%, transparent 75%, rgba(0, 0, 0) 100%);
  border-radius: ${borderRadius};
`

const Content = styled(Column)`
  height: 100%;
  justify-content: space-between;
  padding: 0.25em 0.5em;
  position: relative;
`

const Body = styled.div`
  flex: 1;
`

const Footer = styled(CenteredRow)<RowContent>`
  justify-content: ${justifyContent};
  padding-bottom: 0.125em;
`

const Duration = styled(Text).attrs({ bold: true, color: 'white', size: 0.75 })``

interface HeartProps {
  color: Color
}

const heartCss = css<HeartProps>`
  fill: ${({ color, theme: { colors } }) => colors[color]};
  width: 1em;
  height: 1em;
`

const Reusable = styled(HeartBorderIcon)`
  ${heartCss}
`

const Reused = styled(HeartSolidIcon)`
  ${heartCss}

  margin-left: 0.25em;
`

interface PostcodeProps {
  isUlez?: boolean
}

const Postcode = styled(Text).attrs<PostcodeProps>(({ isUlez }) => ({
  color: isUlez ? 'tertiary' : 'white',
  size: 0.75,
}))<PostcodeProps>``

const TopRow = styled(CenteredRow)<RowContent>`
  justify-content: ${justifyContent};
`

const Price = styled(Text).attrs(({ color = 'white ' }) => ({ color, semibold: true, size: 0.9375 }))`
  padding-bottom: 2px;
`

const Title = styled(Text).attrs({ cold: true, color: 'white', size: 0.875 })`
  margin-bottom: 0.25em;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

const rootCss = css`
  aspect-ratio: 1;
  color: white;
  position: relative;
  text-decoration: none;
`

const StyledLink = styled(Link)`
  ${rootCss};
`

export const PassiveRoot = styled(Column)`
  ${rootCss}
`

const AnchorRoot = styled.a`
  ${rootCss}
`

const ActiveRoot = ({ to, ...props }: LinkProps) => {
  const isBookingHost = window.location.hostname.includes('bookings')
  const isLocalHost = window.location.hostname.includes('localhost')

  if (isBookingHost || isLocalHost) return <StyledLink to={to} {...props} />

  const href = isStagingEnv ? `${LOVEJUNK_STAGING_BOOKINGS_URL}${to}` : `${LOVEJUNK_BOOKINGS_URL}${to}`

  return <AnchorRoot href={href} {...props} />
}

export default ListingsItem
