import { AdvertItem, hasAnyRole, isCollector, isJunkLover, Render, RootState, Transform } from '@lovejunk/core'
import { map, times } from 'lodash/fp'
import React, { FC, ReactNode, useCallback, useMemo } from 'react'
import { connect } from 'react-redux'
import { styled } from 'styled'
import { PerPageConfig } from 'types'
import { device } from 'utils/css'
import { isMobile } from 'utils/environment'

import Item from './Item'
import PlaceholderItem from './PlaceholderItem'

interface MetaProps {
  hasAnyRole: boolean
  isCollector: boolean
  isJunkLover: boolean
}

type StateProps = MetaProps

interface OwnProps {
  columns: number
  items: AdvertItem[]
  itemsPerPage: number

  disableNavigation?: boolean
  isLoading?: boolean
}

type Props = StateProps & OwnProps

export const getPerPage: Transform<PerPageConfig, number> = ({ mobile, desktop }) => (isMobile ? mobile : desktop)

const Listings: FC<Props> = ({ columns, isLoading, items, itemsPerPage, disableNavigation, ...metaProps }) => {
  const renderPlaceholder = useCallback<Render<number>>(i => <PlaceholderItem key={`placeholder-${i}`} />, [])
  const renderItem = useCallback<Render<AdvertItem>>(
    props => <Item {...props} {...metaProps} key={props.id} passive={disableNavigation} />,
    [disableNavigation, metaProps],
  )

  const mapRenderItem = useMemo(() => map<AdvertItem, ReactNode>(renderItem), [renderItem])
  const mapRenderPlaceholder = useMemo(() => times(renderPlaceholder), [renderPlaceholder])

  return <Root columns={columns}>{isLoading ? mapRenderPlaceholder(itemsPerPage) : mapRenderItem(items)}</Root>
}

interface RootProps {
  columns: number
}

const Root = styled.div<RootProps>`
  display: grid;
  grid-template-columns: repeat(${({ columns }) => columns}, minmax(0, 1fr));
  grid-gap: 0.5em;

  @media ${device.mobile} {
    grid-gap: 0.125em;
  }
`

const mapStateToProps = (rootState: RootState) => ({
  hasAnyRole: hasAnyRole(rootState),
  isCollector: isCollector(rootState),
  isJunkLover: isJunkLover(rootState),
})

export default connect(mapStateToProps)(Listings)
