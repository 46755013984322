import React, { FC } from 'react'
import { ThemeProps } from 'styled'
import styled, { keyframes } from 'styled-components'

import { PassiveRoot as Root } from './Item'

const animation = keyframes`
  0% { background-position-x: 0 }
  100% { background-position-x: -1000px }
`

export const PlaceholderItem: FC = () => (
  <Root>
    <Background />
  </Root>
)

export const Background = styled.div<ThemeProps>`
  animation-duration: 1.25s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: ${animation};
  animation-timing-function: linear;

  background: ${({ theme: { colors } }) =>
    `linear-gradient(to right, ${colors.lightGrey} 40%, ${colors.grey} 50%, ${colors.lightGrey} 60%)`};
  background-size: 1000px auto;
  height: 100%;
`

export default PlaceholderItem
